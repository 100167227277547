export const METHOD = {
  GET: 'GET',
  POST: 'POST',
};

export const makeApiMethod = (apiFetch, method) => {
  return (endpoint, options) => apiFetch(endpoint, { ...options, method });
};

// error history 를 담고 있으면 좋다.
export class CustomError extends Error {
  constructor(message, status, errorHistory = []) {
    super(message);
    this.status = status;
    this.errorHistory = errorHistory;
  }
}

export function CustomError2(message, statusCode) {
  Error.call(this, message);
  this.statusCode = statusCode;
}
CustomError2.prototype = Object.create(Error.prototype);
CustomError2.prototype.constructor = CustomError2;

export const createError = (message, status, errorHistory) => {
  return new CustomError(message, status, errorHistory);
};

Promise.simple = function (data) {
  return new this.prototype.constructor((resolve) => {
    resolve(data);
  });
};

export class ServerError extends CustomError {
  constructor(errorHistory) {
    super('server error', 500, errorHistory);
  }
}

const makeError = (errorCode, msg) => {
  if (errorCode === 500) {
    return new ServerError();
  }
  return new CustomError(msg, errorCode);
};

Promise.simpleError = function (errorCode, msg) {
  return new this.prototype.constructor((_, reject) => {
    const err = makeError(errorCode, msg);
    reject(err);
  });
};

export const errorCommon = (error) => {
  console.error(error);
  showError({ statusCode: 500 });
};
