import { get } from './coreAdmin';
import { toStringFromQueryParamNoKeyB, DataParseError } from 'daco-common';

/**
 * 추천검색어 관리 > 추천검색어 조회
 * @returns
 */
export const fetchSuggests = () => {
  return get(`/suggests`, {});
};

/**
 * 서비스 관련 리스트 조회 - 서비스 페이지 하단 관련 고객사례리스트로 사용
 * @param {*} linkUrl
 * @returns
 */
export const fetchServices = (linkUrl) => {
  return get(`/services/${linkUrl}`, {});
};

// 고객사례 리스트 조회 > 고객사례리스트 페이지
export const fetchPartnerCases = (businessId, pageIdx, itemCount) => {
  const queryParam = {
    business: businessId,
    pageIndex: pageIdx,
    itemCount: itemCount,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/partner-cases${queryParamString}`, {});
};

// 고객사례 상세 조회 - 고객사례상세페이지
export const fetchPartnerCase = (id) => {
  return get(`/partner-cases/${id}`, {});
};

/**
 * 뉴스 구분
 * REPORT: 언론보도
 * INSIGHT: 인사이트
 * NOTICE: 알립니다
 */
export const NEWS_TYPE = {
  REPORT: 'REPORT', // 언론보도
  INSIGHT: 'INSIGHT', // 인사이트
  NOTICE: 'NOTICE', // 알립니다
};

// news API > news detail
// 뉴스 리스트 조회 - 뉴스리스트 페이지
/**
 *
 * @param {*} businessId
 * @param { NEWS_TYPE } newsType
 * @param {*} searchStr
 * @param {*} pageIdx
 * @param {*} itemCount
 * @returns
 */
export const fetchNewsList = (businessId, newsType, pageIdx, itemCount) => {
  const queryParam = {
    newsType,
    itemCount,
    business: businessId,
    pageIndex: pageIdx,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/news${queryParamString}`, {});
};

// 뉴스 상세 조회 - 뉴스상세페이지
export const fetchNews = (id) => {
  return get(`/news/${id}`, {});
};

/**
 * 자료 타입
 *  FILE 파일
 *  LINK 링크
 */
export const INFO_TYPE = {
  FILE: 'FILE',
  LINK: 'LINK',
};

/**
 * 자료실 리스트 조회 - 자료실리스트 페이지
 * @param {*} businessId
 * @param { INFO_TYPE } infoType
 * @param {*} pageIdx
 * @param {*} itemCount
 * @returns
 */
export const fetchInformations = (businessId, infoType, pageIdx, itemCount) => {
  const queryParam = {
    infoType,
    itemCount,
    business: businessId,
    pageIndex: pageIdx,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/information${queryParamString}`, {});
};

export const fetchBusiness = () => {
  return get(`/business`, {}).then((businessList) => {
    return businessList.map((business) => ({
      ...business,
      isSelected: false,
    }));
  });
};

// 메인페이지 조회 - 메인페이지
export const fetchMain = () => {
  return get(`/mains`, {}).then((data) => {
    return data;
  });
};

//통합검색
export const fetchSearchList = (category, searchStr, itemCount) => {
  const queryParam = {
    itemCount,
    category,
    likeText: searchStr,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/search${queryParamString}`, {});
};

/**
 * 고객센터 서비스 리스트 조회
 */
export const fetchCsServices = () => {
  return get(`/cs/services`, {});
};

/**
 * 고객센터 컨텐츠 조회
 */
export const fetchCsContents = (service, tag, tab, pageIndex, itemCount) => {
  const queryParam = {
    tag,
    tab,
    pageIndex,
    itemCount,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/cs/services/${service}${queryParamString}`, {});
};

/**
 * 고객센터 상세페이지 조회
 */
export const fetchCsDetail = (id) => {
  return get(`/cs/content/${id}`, {});
};

/**
 * 고객센터 상세페이지 조회
 */
export const fetchCsSearch = (searchStr, pageIndex, itemCount) => {
  const queryParam = {
    likeText: searchStr,
    pageIndex,
    itemCount,
  };
  const queryParamString = toStringFromQueryParamNoKeyB(queryParam);
  return get(`/cs/search${queryParamString}`, {});
};
