import { METHOD, makeApiMethod } from './common';
import { handlerInteceptorResoponseErrorCommon } from 'daco-common-nuxt3';
import { NotFoundError, ServerError } from 'daco-common';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handlerInteceptorResoponseErrorCommon0 = (obj) => {
  console.log('handlerInteceptorResoponseErrorCommon');
  // response body data 에 따라서도 에러처리를 달리 할 수 있음.
  // fetch then 에서 데이터 처리과정에서 에러가 발생하는 경우도 여기에서 처리된다.
  // 정의되지 않은 에러는 처리할 수 없다.
  const { response } = obj;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { status, data } = response;
  // console.log('handlerInteceptorResoponseErrorCommon status: ', status);
  if (status === 404) {
    throw new NotFoundError();
  }
  if (status === 500) {
    throw new ServerError();
  }
};

const BASE_URL = '/api/v1';

// auto imported
// eslint-disable-next-line no-undef
const apiAdmin = $fetch.create({
  baseURL: BASE_URL,
  headers: {},
  onResponseError: handlerInteceptorResoponseErrorCommon,
});

export const get = (endPoint, options) =>
  makeApiMethod(apiAdmin, METHOD.GET)(endPoint, options).then(
    // res: { success: boolean, data: any, statusCode: number }
    (res) => res.data,
  );

export const post = makeApiMethod(apiAdmin, METHOD.POST);
